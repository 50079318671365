
.modal-card {
  .modal-card-body {
    padding: 0;
    background: transparent;
    .video {
      border-radius: 20px;
    }
  }
}
