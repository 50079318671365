
.flex-div {
  height: calc(100svh - 80px);
  &.start-top {
    align-items: flex-start;
  }
}
.container-inline {
  height: 100%;
  overflow-y: scroll;
  &.full-width {
    width: 100%;
  }
  &.container {
    width: 100%;
    height: calc(100svh - 280px);
    overflow-y: auto;
    padding: 20px 10px;
  }
}
input.input {
  background: #f0f0f0;
  border: 1px dashed #000 !important;
  &:hover {
    background: #c3ebff;
    border-color: #02abff !important;
  }
}
.imgwrap {
  position: relative;
  flex-grow: 1;
  padding-bottom: 200px;
}

.imgwrap img {
  /* <= optional, for responsiveness */
  display: block;
  max-width: 100%;
}

.imgwrap svg {
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 200px;
}

.svgRect {
  fill-opacity: 0.3;
  cursor: pointer;
  &:hover {
    fill: blue;
  }
}

.svgRect[data-selected] {
  fill: blue;
}

.columns-list {
  &.boxed {
    border-radius: 20px;
    padding: 0 20px;
  }
  &.gray {
    background: #fff;
    margin: 0 0 10px 0;
    padding: 5px 10px;
  }
}

.select-word {
  display: flex;
  height: calc(100vh - 80px);
  padding: 10px 0;
}

.svg-drag-select-area-overlay {
  border: 1px dotted gray;
  background-color: rgba(255, 255, 255, 0.4);
}

$tabs-boxed-link-focus-active-background-color: #000;
