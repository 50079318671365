
.control-bar {
  transition: all 0.3s;
  background-color: #209cee;
  position: fixed;
  width: 40%;
  min-height: 80px;
  bottom: 20px;
  right: 20px;
  border-radius: 20px;
  z-index: 100;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25);
  .container {
    padding: 0 20px !important;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title,
    p {
      color: #fff;
    }
    .title {
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 1700px) {
    width: 60%;
  }
  @media only screen and (max-width: 1000px) {
    width: 70%;
  }
  @media only screen and (max-width: 800px) {
    width: calc(100% - 40px);
    height: 80px;
    .title.is-3 {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .control-bar {
    .title {
      font-size: 1.5rem;
    }
  }
}
